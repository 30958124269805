// PrivateRoute.js

import React, { useContext } from 'react';
import Login from '../pages/Login';
import AuthContext from '../context/AuthContext';

const PrivateRoute = ({ element: Element, isAuthenticated, ...rest }) => {
  console.log("Private Route")
  let {user} = useContext(AuthContext)
  
  if (user) {
    console.log("Found USER")
    // console.log(user)
    return Element;
  }else {
    console.log("Not Found USER")
    // console.log(user)
    return <Login />
  }  
  
};

export default PrivateRoute;
