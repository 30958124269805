import React from 'react';

const DownloadButton = () => {
    
  const token = localStorage.getItem('authTokens')
  const handleDownload = () => {
      
        fetch('https://apirobodex.qrcrumbs.com/api/v1/download-excel/', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+ JSON.parse(token)['access_token']
            },
        })
        .then(response => {
            if (response.ok) {
                return response.blob(); // Convert to a Blob object
            }
            throw new Error('Network response was not ok.');
        })
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.xlsx'); // Specify the file name
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch(error => console.error('There was an error downloading the file:', error));
    };

    return <button class="btn btn-outline-secondary" onClick={handleDownload}>Download Excel</button>;
};

export default DownloadButton;
