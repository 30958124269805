import React, { useState, useContext } from 'react';
import AuthContext from '../context/AuthContext'
import DownloadButton from '../utils/DownloadButton';

const FileUploads = () => {
  let {user, logoutUser} = useContext(AuthContext)
  console.log(user)
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const token = localStorage.getItem('authTokens')
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('test_file', file);

    try {
      const response = await fetch('https://apirobodex.qrcrumbs.com/api/v1/create_card/', {
        method: 'POST',
        headers:{
          'Authorization': 'Bearer '+ JSON.parse(token)['access_token']
        },
        body: formData
      });
      const data = await response.json();
      if (response.status === 200){
        setResponse(data);
        setError(null);
      } if (response.status === 403){
        console.log("THERE IS AN ERROR")
        console.log(error)
        setResponse(data);
        setError(null);
      } 
      
    } catch (error) {
      
      setError('Error: ' + error.message);
      setResponse(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div class='container'>
      <div class="container">
        {/* <button onClick={logoutUser} class="btn btn-primary" id="submitButton" type="button">Logout</button> */}
      </div>
      {/* <div class="input-group justify-content-center"> */}
      {/* <form id="uploadForm" onSubmit={handleSubmit} encType="multipart/form-data">
        <label htmlFor="fileInput" >Choose a JPG filessss:</label>
        <input
         class="form-control" 
          type="file" 
          id="fileInput" 
          name="test_file" 
          accept=".jpg,.jpeg" 
          required 
          onChange={handleFileChange} 
        />
        <button type="submit" class="btn btn-outline-secondary">
        {loading ? (
              <div class="spinner-border" role="status">
              <span class="visually-hidden"></span>
            </div>
            ) : (
              'Upload'
            )}
        </button>
      </form>
      </div> */}
      <div class="card card-stats">
      <div id="response" class="card-header">
        Business Card Info
        {response && (
          <ul class="list-group list-group-flush">
            {Object.entries(response).map(([key, value]) => (
              <li class="list-group-item" key={key}>{`${key}: ${value}`}</li>
            ))}
          </ul>
        )}
        {error && <p>{error}</p>}
      </div>
      </div>
      <DownloadButton />
    </div>
  );
};

export default FileUploads;
