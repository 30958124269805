import React, { useContext } from 'react'
import AuthContext from '../context/AuthContext'

const Login = () => {
  let {loginUser} = useContext(AuthContext)
  console.log("FROM LOGIN JS")
  
  return (
    <div class="div-center">
    <div class="input-group justify-content-center" style={{ position:'absolute'}} >
      <form onSubmit={loginUser}>
            <div class="form-floating mb">
              <input class="form-control" type='text' name='username' placeholder='Enter Username'/>
        
              
            </div>
            <div class="form-floating mb">
              <input class="form-control" type='password' name='password' placeholder='Enter Password'/>
              
              
            </div>
            <div class="d-grid">
              <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Login</button>
            </div>
      </form>
    </div>
    </div>
  )
}

export default Login