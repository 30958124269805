import React, {useContext} from 'react'
import AuthContext from '../context/AuthContext'
import { Link } from 'react-router-dom'
const Header = () => {
  let {user, logoutUser} = useContext(AuthContext)
  return (
    <div>
      <ul class="nav justify-content-center">
        {user ? (
          <>
        <li class="nav-item"><Link class="nav-link" to="/fs">Home</Link></li>
        <li onClick={logoutUser} class="nav-item"><Link class="nav-link">Logout</Link></li>
        <li class="nav-item"><Link class="nav-link" to="/dashboard">Dashboard</Link></li>
        {/* <li class="nav-item">
          <a class="nav-link disabled" aria-disabled="true">Disabled</a>
        </li> */}
        </>
        ): (<></>)}
      </ul>
      {/* <button class="mdl-button mdl-js-button mdl-button--fab mdl-button--colored">
  <i class="material-icons">add</i>
</button> */}
    </div>
  )
}

export default Header