import './App.css';
import Login from './pages/Login';
import { AuthProvider } from './context/AuthContext'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FileUploadComponent from './pages/FileUploadComponent';
import PrivateRoute from './utils/PrivateRoute';
import Header from './context/Header';
import FileUploads from './pages/FileUploads';
function App() {
  const isAuthenticated = false;
  return (
    <div className="App">
      <Router>
        <AuthProvider>
        <Header />
        <Routes>
         <Route path="/" element={<Login />} exact/>
    
         <Route
            path="/dashboard"
            element={
              <PrivateRoute element={<FileUploadComponent />}
              
              />
            }
      
          />
          <Route
            path="/fs"
            element={
              <PrivateRoute element={<FileUploads />}
              
              />
            }
      
          />
        
        </Routes>
        </AuthProvider>
      </Router>
      
      
    </div>
  );
}

export default App;
